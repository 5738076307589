/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect } from 'react';
import { useUserAuth } from '@/components/UserProvider';
import { logAnalyticsEvent, AmplitudeEventName, PageProperties } from '@/utils/AnalyticsHelper';
import { GtmData, GtmService } from '@/utils/GtmService';

export enum GTMEvent {
  VIEW = 'View',
  HOMEPAY_NANNY_TAX_CALCULATOR_LEAD = 'homepay_nanny_tax_calculator_lead',
}

export type GTMTrackingOptions = {
  event?: GTMEvent;
  data?: Record<string, any>;
  fireOnMount?: boolean;
  pageProperties: PageProperties;
  slots?: string[];
};

export default function useGTMTracking(options: GTMTrackingOptions) {
  const {
    pageProperties,
    slots = [],
    data = {},
    fireOnMount = true,
    event = GTMEvent.VIEW,
  } = options;
  const { auth } = useUserAuth();
  const gtmData: GtmData = {
    ...data,
    ...(auth?.isLoggedIn && { memberId: auth.memberUuid }),
    gtmEvent: event,
    event,
    slots,
  };

  const logSlotsSent = (eventProps: Record<string, any>) => {
    logAnalyticsEvent({
      data: eventProps,
      name: AmplitudeEventName.slotSent,
      pageProperties,
    });
  };

  useEffect(() => {
    if (fireOnMount) {
      GtmService.pushEvent(gtmData);
      logSlotsSent({ ...data, slots, event });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    triggerSlots: string[] = [],
    triggerData: Record<string, any> = {},
    ctaData?: Record<string, any>
  ) => {
    GtmService.pushEvent({
      ...gtmData,
      ...triggerData,
      slots: triggerSlots,
    });
    logSlotsSent({ ...data, ...triggerData, slots: triggerSlots, event });
    logAnalyticsEvent({
      data: { ...ctaData, ...data, ...triggerData },
      name: AmplitudeEventName.ctaInteracted,
      pageProperties,
    });
  };
}
