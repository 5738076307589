import { Box, Button, Container, Grid, Hidden, Link, Typography } from '@mui/material';
import { theme } from '@care/material-ui-theme';
import NextLink from 'next/link';
import getConfig from 'next/config';
import Image from 'next/image';
import { PAGE_PROPERTIES, PHONE_LABEL, PHONE_LINK } from '@/constants';
import { NextConfig } from '@/interfaces';
import { AmplitudeEventName, logAnalyticsEvent } from '@/utils/AnalyticsHelper';
import leads from '@/assets/images/leads.png';

const { publicRuntimeConfig } = getConfig() as NextConfig;

const { SERVICE_FAQ_URL } = publicRuntimeConfig;

const Help = () => {
  return (
    <Box
      sx={{
        [theme.breakpoints.down('md')]: {
          height: '600px',
        },
        backgroundColor: theme.palette.care?.blue[100],
        height: '530px',
        textAlign: 'center',
      }}>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Box
              display="inline-flex"
              height="250px"
              margin="0 auto"
              position="relative"
              width="300px">
              <Image
                alt="Here to help"
                height={250}
                layout="intrinsic"
                loading="lazy"
                src={leads}
                width={300}
              />
              <Typography
                variant="h1"
                sx={{
                  margin: '16px 0px',
                  position: 'absolute',
                  textAlign: 'center',
                  top: '181px',
                  width: 'inherit',
                }}>
                We&apos;re here to help
              </Typography>
            </Box>
            <Typography variant="body1">
              Our award-winning Tax, Payroll &amp; HR specialists understand that every
              family&apos;s situation
              <Hidden mdDown>
                <br />
              </Hidden>
              is unique. We are here to answer your questions quickly and accurately.
            </Typography>
            <Typography m={2} variant="h3">
              Call us for a personalized consultation{' '}
              <Box component="span">
                <Hidden mdDown>
                  <Typography variant="h4" color={theme.palette.care?.blue[700]} component="span">
                    {PHONE_LABEL}
                  </Typography>
                </Hidden>
                <Hidden mdUp>
                  <br />
                  <NextLink href={PHONE_LINK} passHref>
                    <Link color={theme.palette.care?.blue[700]} variant="h4" href={PHONE_LINK}>
                      {PHONE_LABEL}
                    </Link>
                  </NextLink>
                </Hidden>
              </Box>
            </Typography>
            <Box m="16px">
              <Button
                sx={{
                  width: 'auto',
                }}
                color="secondary"
                data-testid="home-cta-review-faqs-btn"
                href={SERVICE_FAQ_URL}
                onClick={() => {
                  logAnalyticsEvent({
                    data: {
                      cta_location: 'HomePay VHP',
                      cta_text: 'Review our FAQS',
                    },
                    name: AmplitudeEventName.ctaInteracted,
                    pageProperties: PAGE_PROPERTIES.mainVHP,
                  });
                }}
                variant="contained">
                Review our FAQs
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Help;
