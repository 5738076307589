import { Card } from '@care/react-component-lib';
import { Box, Button, CardContent, Grid, Hidden, Link, Typography, useTheme } from '@mui/material';
import NextLink from 'next/link';
import getConfig from 'next/config';
import { FC } from 'react';
import {
  HIRE_STATUS,
  HireStatusOption,
  PAGE_PROPERTIES,
  ROUTING_PROPERTIES,
  TBF_SECTION_SCROLL_CLASS,
} from '@/constants';
import { getEnrollmentUrl } from '@/utils/urlHelper';
import { AmplitudeEventName, logAnalyticsEvent, PageProperties } from '@/utils/AnalyticsHelper';
import { NextConfig } from '@/interfaces';

const { publicRuntimeConfig } = getConfig() as NextConfig;
const { HOMEPAY_URL } = publicRuntimeConfig;

interface Props {
  ctaLocation?: string;
  pageProperties: PageProperties;
}

const TrustBuildingFlowCard: FC<Props> = ({ ctaLocation, pageProperties }) => {
  const theme = useTheme();
  const handleAnswer = (answer: HireStatusOption) =>
    logAnalyticsEvent({
      data: {
        enrollment_step: 'Hire status',
        hire_status: answer.text,
      },
      name: AmplitudeEventName.homePayEnrollmentStarted,
      pageProperties: PAGE_PROPERTIES.mainVHP,
    });

  const handleCaregiverClick = () => {
    logAnalyticsEvent({
      data: {
        cta_location: `Enrollment CTA - ${ctaLocation}`,
        cta_text: "I'm a caregiver",
      },
      name: AmplitudeEventName.ctaInteracted,
      pageProperties,
    });
  };

  return (
    <Card
      careVariant="subtle"
      sx={{
        margin: '8px 4px 0 4px',
        minHeight: '205px',
        zIndex: 25,
        [theme.breakpoints.up('sm')]: {
          margin: '15px 0 0',
          minHeight: '166px',
          width: '494px',
          zIndex: 1,
        },
      }}
      className={TBF_SECTION_SCROLL_CLASS}
      data-testid="tbf-card">
      <CardContent
        sx={{
          [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2.5),
          },
        }}>
        <Box
          sx={{
            marginBottom: theme.spacing(4),
            paddingTop: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
              marginBottom: 0,
              paddingTop: 0,
            },
          }}>
          <Typography align="center" variant="h2">
            To get started, have you hired{' '}
            <Hidden xsUp>
              <br />
            </Hidden>
            a caregiver?
          </Typography>
        </Box>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={6}>
            <Box textAlign="center">
              <Button
                sx={{
                  textAlign: 'center',
                  [theme.breakpoints.down('md')]: {
                    margin: '15px auto 10px',
                    minWidth: 0,
                    width: '100%',
                  },
                }}
                color="primary"
                data-testid="home-cta-yes-btn"
                href={getEnrollmentUrl(HIRE_STATUS.yes)}
                onClick={() => handleAnswer(HIRE_STATUS.yes)}
                size="large"
                variant="contained">
                Yes
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box textAlign="center">
              <Button
                sx={{
                  textAlign: 'center',
                  [theme.breakpoints.down('md')]: {
                    margin: '15px auto 10px',
                    minWidth: 0,
                    width: '100%',
                  },
                }}
                color="primary"
                data-testid="home-cta-not-yet-btn"
                href={getEnrollmentUrl(HIRE_STATUS.notYet)}
                onClick={() => handleAnswer(HIRE_STATUS.notYet)}
                size="large"
                variant="outlined">
                Not yet
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            marginTop: theme.spacing(2.5),
            textAlign: 'center',
            [theme.breakpoints.down('md')]: {
              marginTop: theme.spacing(0.5),
            },
          }}
          onClick={handleCaregiverClick}>
          <NextLink href={`${HOMEPAY_URL}${ROUTING_PROPERTIES.caregiver.relativeURL}`} passHref>
            <Link underline="none">
              <Typography color="secondary" variant="body1">
                I&apos;m a caregiver
              </Typography>
            </Link>
          </NextLink>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TrustBuildingFlowCard;
